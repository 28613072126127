<template>
  <v-card>
    <v-list>
      <v-subheader>
        Banner/Canvas Links
        <v-spacer />
        <v-switch v-if="isBannerAdminUser" v-model="pprd" :label="pprd ? 'PPRD' : 'PROD'" />
      </v-subheader>
      <v-alert v-if="bannerOffline" type="info">Banner is currently offline doing its daily backup. This occurs daily (except Monday and Saturday) from midnight until 3am.</v-alert>
      <template v-for="({ href, link, title, subtitle }, index) in links">
        <v-list-item v-if="href" :key="'href-' + index" :href="href" target="_blank">
          <v-list-item-content>
            <v-list-item-title>{{ title }}</v-list-item-title>
            <v-list-item-subtitle v-if="subtitle">{{ subtitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else-if="link" :key="'link-' + index" :to="link">
          <v-list-item-content>
            <v-list-item-title>{{ title }}</v-list-item-title>
            <v-list-item-subtitle v-if="subtitle">{{ subtitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-subheader>Tech Links</v-subheader>
      <v-list-item to="/printers">
        <v-list-item-content>
          <v-list-item-title>Printers</v-list-item-title>
          <v-list-item-subtitle>Install Covenant Printers</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <microsoft-dialog></microsoft-dialog>
    </v-list>
  </v-card>
</template>
<script>
import { ref, computed, onMounted, onBeforeUnmount } from '@vue/composition-api'

export default {
  components: {
    MicrosoftDialog: () => import('./banner/microsoftDialog')
  },
  setup (props, { root }) {
    const user = computed(() => 'spoof' in root.$store.state.user ? root.$store.state.user.spoof : root.$store.state.user)
    const roles = computed(() => user.value.roles)
    const isBannerAdminUser = computed(() => roles.value.filter((role) => ['Financial Aid', 'Records', 'Technology Services', 'Accounting', 'Student Development', 'Center for Calling & Career', 'Human Resources'].includes(role)).length > 0)
    const bannerOffline = ref(true)
    const hasWorkstudy = ref(false)
    const links = computed(() => {
      const arr = []
      if (!bannerOffline.value && roles.value.filter((role) => role === 'Student' || role === 'Students' || role === 'All Students').length > 0) {
        arr.push({
          href: 'https://knox01.covenant.edu:9543/StudentSelfService/ssb/studentProfile',
          title: 'Student Profile'
        })
        arr.push({
          href: 'https://knox01.covenant.edu:9543/StudentSelfService/ssb/financialAid',
          title: 'Financial Aid'
        })
      }
      if (!bannerOffline.value && roles.value.filter((role) => role === 'Employee' || role === 'All Staff' || role === 'All Faculty').length > 0) {
        if (pprd.value) {
          arr.push({
            href: 'https://knox02.covenant.edu:9243/EmployeeSelfService',
            title: 'Employee Profile (PPRD)'
          })
          arr.push({
            href: 'https://knox02.covenant.edu:9243/FinanceSelfService',
            title: 'Banner Finance (PPRD)'
          })
          if (isBannerAdminUser.value) {
            arr.push({
              href: 'https://wycliffe02.covenant.edu:9143/applicationNavigator',
              title: 'Banner Admin (PPRD)'
            })
          }
        } else {
          arr.push({
            href: 'https://knox01.covenant.edu:9543/EmployeeSelfService',
            title: 'Employee Profile'
          })
          arr.push({
            href: 'https://knox01.covenant.edu:9543/FinanceSelfService',
            title: 'Banner Finance'
          })
          if (roles.value.filter((role) => ['Financial Aid', 'Records', 'Technology Services', 'Accounting', 'Student Development', 'Center for Calling & Career', 'Human Resources'].includes(role))) {
            arr.push({
              href: 'https://wycliffe01.covenant.edu:9443/applicationNavigator',
              title: 'Banner Admin'
            })
          }
        }
      } else if (!bannerOffline.value && hasWorkstudy) {
        arr.push({
          href: 'https://knox01.covenant.edu:9543/EmployeeSelfService',
          title: 'Employee Profile'
        })
      }
      arr.push({
        href: 'https://covenantcollege.instructure.com/login/saml',
        title: 'Canvas'
      })
      return arr
    })

    const interval = ref(null)
    function checkBannerOnline () {
      const dt = new Date()
      const [date, time] = dt.toLocaleString('en', { timeZone: 'America/New_York' }).split(', ')
      const day = new Date(date).getDay()
      const isAm = time.substring(time.length - 2) === 'AM'
      const hour = parseInt(time.substring(0, time.indexOf(':')))
      if (day !== 1 && day !== 6 && isAm && (hour < 3 || hour === 12)) bannerOffline.value = true
      else bannerOffline.value = false
    }

    onMounted(async () => {
      checkBannerOnline()
      // Check to see if they have work-study; if so, then add the Banner Employee Profile linke
      const { pidm } = user.value
      if (roles.value.filter((role) => role === 'Employee' || role === 'All Staff' || role === 'All Faculty').length === 0) {
        const placements = await root.$store.dispatch('work-study/loadPlacements', { pidm })
        if (placements.length > 0) hasWorkstudy.value = true
      }

      interval.value = setInterval(() => checkBannerOnline(), 30000)
    })

    onBeforeUnmount(() => {
      clearInterval(interval.value)
    })

    const pprd = ref(false)

    return {
      user,
      roles,
      isBannerAdminUser,
      bannerOffline,
      links,
      interval,
      checkBannerOnline,
      pprd
    }
  }
}
</script>
